import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import swatches from '../utils/swatches';
import Header from '../components/Header';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import media from '../utils/breakpoints';

const StyledTitle = styled.h1`
  text-decoration: unset;
  color: currentColor;
  font-family: brother-1816, sans-serif;
  font-weight: 400;
`;

const Underline = styled.span`
  box-shadow: 
    inset 0px -0.2rem 0px 0px white, 
    inset 0px -1.2rem 0px 0px #BFD556;
`;

const StyledContainer = styled.div`
  padding: 90px 5px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 40px);
`;

const StyledLayout = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const TitleBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background: ${swatches.white};
  width: 100%;
`;

const StyledContent = styled.div`
  a {
    color: ${swatches.red};
  }
`;

class AboutPage extends React.Component {
  render() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges');
    const aboutPage = posts.filter(p => get(p, 'node.frontmatter.page'))[0].node;
    const title = get(aboutPage, 'frontmatter.title');

    return (
      <Fragment>
        <SEO />
        <Header/>
        <StyledContainer>
          <TitleBackground>
            <StyledLayout>
              <StyledTitle><Underline>{title}</Underline></StyledTitle>
            </StyledLayout>
          </TitleBackground>
          <StyledLayout>
            <StyledContent dangerouslySetInnerHTML={{ __html: aboutPage.html }} />
          </StyledLayout>
        </StyledContainer>
        <Footer />
      </Fragment>
    )
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          html
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            page
          }
        }
      }
    }
  }
`;
